import { Broadcast } from 'features/broadcast/broadcastsSlice';
import { HubBroadcastStart } from 'features/hub/hub';

export const mapHubBroadcastStart = ({
  broadcast: hubEvent
}: HubBroadcastStart): Broadcast => {
  return {
    uid: hubEvent.uid.toString(),
    title: hubEvent.title,
    duration: hubEvent.duration,
    categoryName: hubEvent.category_name,
    isLive: true,
    url: hubEvent.streams.progressive.url,
    listenerCount: hubEvent.listener_count || 0,
    startedAt: hubEvent.started_at,
    eventId: hubEvent.event_id?.toString() || null,
    finished: false,
    fallback: hubEvent.fallback || false
  };
};
