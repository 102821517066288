import Picker, { IEmojiPickerProps } from 'emoji-picker-react';
import React, { FunctionComponent, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { Icon } from 'components/Icons';
import styles from './styles.module.scss';
import { useRect } from 'react-use-rect';

export const EmojiPicker: FunctionComponent<IEmojiPickerProps> = (props) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const [pickerPosition, setPickerPosition] = useState<'right' | 'left'>(
    'right'
  );
  const [pickerButtonRef] = useRect((pickerButtonRefRect) => {
    setPickerPosition(pickerButtonRefRect.left < 100 ? 'left' : 'right');
  });

  const handleEmojiClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(pickerRef, handleEmojiClickOutside);

  return (
    <div ref={pickerRef} className={styles['emoji-picker']}>
      <button
        ref={pickerButtonRef}
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'emoji'} hidden={true} label={'Choose emoji'} />
      </button>
      {pickerOpen && (
        <div
          className={`tw-border tw-border-solid tw-border-line-color tw-absolute tw-bottom-full ${
            pickerPosition === 'left' ? 'tw-left-0' : 'tw-right-0'
          } tw-shadow-xl tw-rounded-xl tw-overflow-hidden tw-h-[280px] md:tw-h-[320px] mobile-landscape:tw-h-[220px] tw-z-50 tw-max-w-[280px] w-full`}
        >
          <Picker disableAutoFocus={true} {...props} />
        </div>
      )}
    </div>
  );
};
