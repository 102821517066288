import React, { FunctionComponent, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageAnimationFullScreen } from 'components/LayoutAnimations/PageAnimationFullScreen';
import { RecordingHeaderTags } from './components/RecordingHeaderTags';
import { RecordingWrapper } from './components/RecordingWrapper';
import { RecordingHeader } from './components/RecordingHeader';
import { RecordingContent } from './components/RecordingContent';
import { setCurrentRecording } from 'features/recordings/recordingsSlice';
import { setAudioUrl } from 'features/audio/audioSlice';
import { useRecording } from 'features/recordings/recordingHooks';
import { Loader } from 'components/Loader';
import { useFetchRecording } from 'features/recordings/recordingHooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { RecordingEmbed } from './components/RecordingEmbed';
import { ErrorEmbed } from 'components/ErrorPages/ErrorEmbed';
import { RecordingDeepLinkBanner } from './components/RecordingDeepLinkBanner';
import { useAppSelector } from 'hooks/redux';
import { useAudioMediaSession } from 'features/audio/audioHooks';

export const Recording: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const recording = useRecording(id);
  const recordingisLoading = useSelector(
    (state: RootState) => state.recordings.isLoading
  );

  const location = useLocation() as any;
  const minimiseUrl = (location.state && location.state.prevPath) || '/';
  const { isListenerAppWebview, isEmbed, isNativeAppWebview } =
    useContext(UserAgentContext);
  const { postMessage } = useNativeEvents();

  const isRecordingUrlSet = useRef<boolean>(false);
  const currentRecordingSessionId = useSelector(
    (state: RootState) => state.recordings.currentRecordingSessionId
  );
  const embedOnly = useAppSelector(
    (state) => state.channel?.channelDetails?.embedOnly || false
  );

  const navigate = useNavigate();

  const hideLoader: boolean = location.state && location.state.hideLoader;

  useFetchRecording(id, hideLoader);

  useAudioMediaSession(
    recording?.title,
    recording?.artwork.image.medium,
    'recording'
  );

  useEffect(
    function listenerAppPostRecordingUrl() {
      if (
        isListenerAppWebview &&
        recording &&
        !isRecordingUrlSet.current &&
        currentRecordingSessionId
      ) {
        isRecordingUrlSet.current = true;
        const audioUrl =
          process.env.REACT_APP_ENV_NAME === 'development'
            ? 'https://ilo-voices.cdn.prismic.io/ilo-voices/c336ed2f-9e7f-4303-a426-590a63fd8462_The+Future+of+Work+Podcast+-+Green+Jobs+%28French%29.mp3'
            : recording.url;
        postMessage({
          audioUrl: audioUrl,
          audioArtworkUrl: recording.artwork.image.medium,
          audioId: recording.id,
          audioOwnerId: recording.userId,
          audioSessionId: currentRecordingSessionId,
          audioTitle: recording.title
        });
      }
    },
    [isListenerAppWebview, recording, postMessage, currentRecordingSessionId]
  );

  useEffect(() => {
    if (id) {
      dispatch(setCurrentRecording(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (recording) {
      dispatch(setAudioUrl(recording.url));
    }
  }, [recording, dispatch]);

  if (recordingisLoading) {
    return <Loader fillComponent={true} hideOnNative={isNativeAppWebview} />;
  }

  if (!recording) {
    return <ErrorEmbed type={'recording'} />;
  }

  if (isEmbed) {
    return <RecordingEmbed recording={recording} />;
  }

  if (embedOnly === true) {
    navigate('../404');
    return null;
  }

  return (
    <PageAnimationFullScreen>
      <RecordingHeaderTags recording={recording} />
      <RecordingWrapper recording={recording}>
        <RecordingDeepLinkBanner />
        <RecordingHeader minimiseUrl={minimiseUrl} />
        <RecordingContent recording={recording} />
      </RecordingWrapper>
    </PageAnimationFullScreen>
  );
};
